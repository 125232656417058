.cluster img {
  transform: translateY(-9%) translateX(-9%);
  width: 120%;
  height: 120%;
}

.searchResult {
  padding: 0 1rem;
  border: 1px black dotted;
}
